import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Stack,
  Typography,
} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {
  UseFormWatch,
  UseFormSetValue,
  FieldErrors,
  Control,
  UseFormClearErrors,
} from "react-hook-form"
import React from "react"
import CreditCardPaymentPrice from "./CreditCard"
import DebitCardPaymentPrice from "./DebitCard"
import PixPaymentPrice from "./Pix"
import BoletoPaymentPrice from "./Boleto"
import {
  IProductPrice,
  IProductPricePayment,
  IProductPricePaymentInstallment,
  TPaymentMethodEnum,
} from "../../../../../core/types/Product"
import { TSale } from "../../../../../core/types/Sale"
import { checkoutPayment4Style } from "./PaymentConsts"
import PaymentFunctions from "../../../../../pages/Checkout/Payment/PaymentFunctions"
import { PaymentData } from "../../../../../core/types/Order"
import Promises from "../../../../../core/functions/promises"

export interface IPaymentsByProduct4Props {
  loading: boolean
  data: IProductPrice | null
  control: Control<TSale, any>
  watch: UseFormWatch<TSale>
  setValue: UseFormSetValue<TSale>
  errors: FieldErrors<TSale>
  installments?: IProductPricePaymentInstallment[]
  clearErrors: UseFormClearErrors<TSale>
}

/**
 * Componente responsável por gerenciar as formas de pagamentos no checkout.
 */
const PaymentsByProduct4 = (props: IPaymentsByProduct4Props) => {
  const {
    loading,
    data,
    setValue,
    control,
    errors,
    watch,
    installments,
    clearErrors,
  } = props

  const paymentMethods =
    data?.paymentMethods.filter(
      (pm) => ![TPaymentMethodEnum.CreditCardAndPix].includes(pm.methodType)
    ) ?? []

  const maxPaymentLine = 4
  const isMorePayments = (paymentMethods.length ?? 0) > maxPaymentLine

  const [selected, setSelected] = React.useState(0)
  const [openMorePay, setOpenMorePay] = React.useState(!isMorePayments)
  const [isPaymentVisible, setIsPaymentVisible] = React.useState(true)

  const paymentMethodType = watch("payment.methodType")
  const payments = watch("payment.payments")

  React.useEffect(() => {
    if (paymentMethodType !== selected) {
      setSelected(paymentMethodType)
    }
  }, [paymentMethodType, selected])

  const handleChange = async (
    event: React.SyntheticEvent,
    newValue: TPaymentMethodEnum | null | undefined
  ) => {
    if (!newValue) {
      setOpenMorePay(!openMorePay)
      return
    }
    const isChanged = selected !== newValue
    try {
      if (isChanged) {
        setValue("payment.payments", [])
        setIsPaymentVisible(false)
      }

      setSelected(newValue)
      setValue("payment.methodType", newValue)

      if (isChanged) {
        await Promises.delay(200)
      }
    } finally {
      if (isChanged) {
        setIsPaymentVisible(true)
      }
    }
  }

  const getPaymentMethods = () => {
    return isMorePayments && !openMorePay
      ? paymentMethods.filter((pm, i) => i < maxPaymentLine - 1)
      : paymentMethods
  }

  const getPaymentIcon = (
    payment: IProductPricePayment | undefined,
    isValid: boolean
  ) => {
    if (!payment) {
      if (openMorePay) {
        return <img src="/assets/icons/actions/cancel.svg" alt="Ver menos" />
      }
      return <img src="/assets/icons/payments/plus.svg" alt="Ver mais" />
    }
    return checkoutPayment4Style[payment.methodType].icon[isValid ? 1 : 0]
  }

  const getPaymentDescription = (payment: IProductPricePayment | undefined) => {
    if (!payment) {
      return openMorePay ? "Ver menos" : "Ver mais"
    }
    return checkoutPayment4Style[payment.methodType].label
  }

  const isPaymentValid = (payment: IProductPricePayment | undefined) => {
    if (!payment) {
      return false
    }
    return PaymentFunctions.isPaymentValid(payments, payment, paymentMethodType)
  }

  const getPaymentItem = (
    payment: IProductPricePayment | undefined,
    index: number
  ) => {
    const testId = `buttonPayment${Number(
      payment?.methodType ?? TPaymentMethodEnum.CreditCard
    )}`
    return (
      <Grid
        key={`pay-${index}`}
        xs={1}
        sm={isMorePayments ? 4 : 16 / (paymentMethods.length || 16)}
      >
        <Button
          fullWidth
          id={testId}
          variant="outlined"
          sx={{
            p: 1.5,
            height: "56px",
            backgroundColor: "white",
            borderColor: isPaymentValid(payment) ? "#1C52BD" : "#C5C6C9",
            color: "#212121",
            textTransform: "none",
          }}
          onClick={(e) => handleChange(e, payment?.methodType)}
          data-testid={testId}
        >
          <Stack
            direction="row"
            spacing={1}
            width="100%"
            alignContent="center"
            alignItems="center"
          >
            {getPaymentIcon(payment, isPaymentValid(payment))}
            <Typography textAlign="start" fontSize="14px" lineHeight="18px">
              {getPaymentDescription(payment)}
            </Typography>
          </Stack>
        </Button>
      </Grid>
    )
  }

  const getPaymentWidget = (pay: PaymentData, index: number) => {
    const key = `${paymentMethodType.toString()}-${pay.paymentMethod.toString()}`
    if (pay.paymentMethod === TPaymentMethodEnum.CreditCard) {
      const title = payments.length > 1 ? `${index + 1}º Cartão` : undefined
      return (
        <CreditCardPaymentPrice
          key={key}
          visible={true}
          title={title}
          control={control}
          errors={errors}
          installments={installments}
          index={index}
          isMultiple={payments.length > 1}
          watch={watch}
          setValue={setValue}
          clearErrors={clearErrors}
        />
      )
    }
    if (pay.paymentMethod === TPaymentMethodEnum.DebitCard) {
      return (
        <DebitCardPaymentPrice
          key={key}
          visible={true}
          control={control}
          index={index}
          watch={watch}
          clearErrors={clearErrors}
        />
      )
    }
    if (pay.paymentMethod === TPaymentMethodEnum.Pix) {
      const title = payments.length > 1 ? "PIX" : undefined
      return (
        <PixPaymentPrice
          key={key}
          visible={true}
          title={title}
          index={index}
          isMultiple={payments.length > 1}
          watch={watch}
          setValue={setValue}
        />
      )
    }
    if (pay.paymentMethod === TPaymentMethodEnum.Boleto) {
      return <BoletoPaymentPrice key={key} visible={true} />
    }
    return <></>
  }

  return (
    <Stack sx={{ width: "100%" }}>
      {loading && <CircularProgress />}
      {!loading && data !== null && (
        <Stack sx={{ width: "100%" }} spacing={3}>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            style={{ overflowY: "hidden" }}
            columns={{
              xs: 1,
              sm: 16,
            }}
            data-cy="containerPaymentOptions"
          >
            {getPaymentMethods().map((payment, index) =>
              getPaymentItem(payment, index)
            )}
            {isMorePayments && getPaymentItem(undefined, -1)}
          </Grid>
          <Fade in={isPaymentVisible} mountOnEnter unmountOnExit>
            <Stack sx={{ width: "100%" }} spacing={3}>
              {payments.map(getPaymentWidget)}
              {payments.length <= 0 && <Box height="300px" />}
            </Stack>
          </Fade>
        </Stack>
      )}
    </Stack>
  )
}

export default PaymentsByProduct4
